@import './file-upload-drag-drop.scss';
@import './startup-page.builder.scss';
@import './page-builder-header.scss';
@import './about-section.scss';
@import './input.scss';
@import './products-and-services.scss';
@import './timeline-and-milestones.scss';
@import './button.scss';
@import './add-team-member-modal.scss';
@import './pitch-deck.scss';
@import './create-start-up-modal.scss';
@import './investment-criteria.scss';
@import './modals.scss';

:root {
  --toastify-z-index: 9999999 !important;
}

* {
  font-display: swap;
}

.ql-editor {
  min-height: 350px;
}

.FileUploadDragDrop-Image {
  border-radius: 20px;
}

html body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
.eventCardGrid {
  padding: 0px;
}
.react-kanban-column {
  padding: 15px;
  border-radius: 2px;
  background-color: #eee;
  margin: 5px;
  min-width: 200px;
}
.react-kanban-column2 {
  padding: 15px;
  border-radius: 2px;
  margin: 5px;
  min-width: 200px;
}
.react-kanban-column-header {
  text-align: center;
}
.kanban-box {
  display: flex;
}
.kanban-icon {
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;

  vertical-align: middle;
  appearance: none;
  text-decoration: none;

  color: rgb(99, 115, 129);
  padding: 8px;
  position: absolute;
  bottom: 15px;
}
.kanban-icon2 {
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;

  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;

  vertical-align: middle;
  appearance: none;
  text-decoration: none;

  color: rgb(99, 115, 129);
  padding: 8px;
  position: revert;
  bottom: 15px;
  color: #4fa7c7;
  width: 50%;
}
.add-blog-container {
  max-width: 1000px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 30px;
  outline: none;
}
.new-kanban-card {
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  background-image: none;
  width: 100%;
  position: relative;
  box-shadow: rgb(145 158 171 / 16%) 0px 1px 2px 0px;
  justify-content: space-between;
  display: flex;
}
.kanban-title {
  margin: 0px;
  font-weight: 600;
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: 'Public Sans', sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 0.48;
}
.react-kanban-board {
  padding: 5px;
  justify-content: center;
}
.kanban-paper {
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  border: 1px solid rgba(145, 158, 171, 0.12);
  background-image: none;
  padding-left: 16px;
  padding-right: 16px;
  background-color: rgba(145, 158, 171, 0.08);
  display: flex;
  justify-content: center;
}
.eventCardMeeting {
  z-index: 1;
  position: absolute;
  min-width: 8rem;
  min-height: 2.08rem;
  background-color: #23aacd;
  color: #ffffff;
  border-radius: 5px 0px 5px 0px;
  margin-left: 1.1rem;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}
.eventCardWebinar {
  z-index: 1;
  position: absolute;
  min-width: 8rem;
  min-height: 2.08rem;
  background-color: #18ce00;
  color: #ffffff;
  border-radius: 5px 0px 5px 0px;
  margin-left: 1.1rem;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}
.eventCardImg {
  width: 100%;
  height: 3.2rem;
  margin-left: 1.1rem;
  z-index: 1;
}

.eventCardPhoto {
  border-radius: 5px;
}
.eventCardTime {
  color: #40c4ea;
}
.eventCardHead {
  width: auto;

  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.7px;
  color: #ee2960;

  font-style: Regular;
}
.eventCardText {
  width: 14rem;
  height: 3.563rem;
  font-weight: 400;
  font-size: 14.8px;
  line-height: 22px;
  letter-spacing: 0.7px;
  margin-top: 0.5rem;
  font-size: 180x;
  line-height: 22px;
  letter-spacing: 0.7px;
  margin-top: 0.6rem;
  color: #404347;
  font-style: Regular;
}
.eventCardIcon {
  color: #2e3a59;
  margin-left: auto;
}

.investorPageCard {
  width: '100%';
  height: 95px;
}
.dragNDropImage {
  border-radius: 20px;
}
.investorDashboard {
  width: 1076px;
  height: 311px;
}
.e-selected {
  span.e-day {
    background-color: #fda92d !important;
    border-color: #fda92d !important;
    color: #fff;
  }
}
.e-today {
  span.e-day {
    border-color: #fda92d !important;
    color: #fda92d !important;
  }
}
.eventSearchFee {
  font-weight: 850;
  font-size: 17px;
  color: #28b700;
  padding-top: 7px;
}
.EventCardAvatarSetting {
  border-radius: 3.125rem;
}
.eventCardSelfy {
  margin-top: 1.3rem;
  float: left;
}
.eventCardUsername {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.098rem;
  letter-spacing: 0.7px;

  color: #929090;
}
.eventCardTopHead {
  text-align: center;
  font-weight: 300;
  line-height: 2rem;
  font-size: 2rem;
  color: #151f2f;
  margin-top: 1rem;
}
.heroSliderCard1 {
  height: 100%;
  flex-direction: column;
  text-align: left;
  background-image: url(../images/hero-slider-bg.svg);
}
.heroSliderCard2 {
  width: 100%;
  flex-direction: column;
  background-image: url(../images/startupdashcard1.jpeg);
  background-repeat: no-repeat;
}
.heroSliderBoxs {
  height: 40rem;
}
.heroLeftBox1 {
  width: 100%;
  float: right;
}
.heroLeftBox2 {
  width: 100%;
  float: right;
}
.heroLeftBox3 {
  padding-right: 10px;
  width: 100%;
  float: right;
}
.heroRightBox1 {
  padding: 3%;
  width: 100%;
  float: left;
  padding-top: 4.4%;
}
.heroLeftBoxltlText {
  padding: 3px;
  font-weight: 500;
  font-size: 1.9rem;
  line-height: 3.688rem;
  color: #151f2f;
  text-shadow: 0rem 0rem 1.813rem #ffffff;
}
.heroLeftBoxBigText {
  padding: 3px;
  font-weight: 600;
  font-size: 16px;
  line-height: 2.188rem;
  color: #5c5f64;
  text-shadow: 0rem 0rem 0.625rem #ffffff;
  float: right;
}
.heroRightBoxltlText {
  font-size: 1.563rem;
  line-height: 1.813rem;
  color: #ffffff;
  text-shadow: 0rem 0rem 1.813rem #999999;
  float: left;
  filter: drop-shadow(1.5px 0px 0px #999999);
  padding-left: 3.2%;
}
.heroRightBoxBigText {
  font-weight: bold;
  font-size: 3.125rem;
  line-height: 3.938rem;
  color: #ffffff;
  text-shadow: 0rem 0rem 1.813rem #999999;
  padding-top: 4%;
  float: left;
  filter: drop-shadow(1.5px 0px 0px #999999);
}
.heroRightBoxButton {
  padding-left: 0.7%;
  text-align: center;
}

.heroButtonStyle {
  width: 100%;
  height: 3.3rem;
  float: left;
  margin-left: 1rem;
}
.pinkButtonStyle {
  background-color: #ee2960;
  box-shadow: 0rem 0.625rem 1.5rem rgba(0, 0, 0, 0.19);
  border-radius: 0.313rem;
  height: 2.25rem;
  width: 8.5rem;
  text-align: center;
  &:hover {
    background-color: #ee2960;
  }
}
.pinkNavBarItem {
  color: #1c1d1f !important;
  transition: all 0.3s !important;
  &:hover {
    color: #ee2960 !important;
    transition: all 0.3s !important;
  }
}

.heroButtonText {
  color: white;
  font-weight: 100;
  font-size: 13px;
  margin-top: 4%;

  height: 100%;
}
.footerMask {
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  border-radius: 0.313rem;
  background-color: orange;
  background-image: url(../images/footer-bg.svg);
  background-size: 600px 300px;
  background-position: right;
  background-repeat: no-repeat;
}
.footerMaskHeader {
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
  padding-top: 0.658rem;
}
.footerMaskDescription {
  font-size: 22px;
  color: #ffffff;
  margin-top: 1.475rem;
  padding-bottom: 0.713rem;
  line-height: 1.563rem;
}
.footerCopyrightTitle {
  text-align: center;
  color: #5c5f64;
  font-size: 14px;
  margin-top: 1.993rem;
}
.navbarSlogan {
  margin-top: 2.3rem;
  margin-left: 0.3rem;
  font-weight: 400;

  font-size: 14px;
  color: #5c5f64;
}

.navbarPagesContainer {
  font-weight: 400;

  font-size: 14px;
  color: #1c1d1f;
}

.resourcesCardMedia {
  height: 16rem;
}
.resourcescardcontent {
  background-color: #acacac;
  width: 100%;
  max-height: 52px;
}
.resourcesCardActionArea:hover {
  .resourcescardtext {
    color: #333;
  }
  .resourcescardcontent {
    background-color: #f7b407;
  }
}
.resourcescardtext {
  line-height: 1.25rem;
  font-size: 24px;
  color: #ffffff;
  text-align: center;
}
.resourcestextboxcard {
  max-width: 27.188rem;
  width: 100%;
  height: 11.563rem;
  border-radius: 2.5rem 2.5rem 0 3.125rem;
  background-color: #23aacd;
}
.resourcestextboxcardtitle {
  position: absolute;
  line-height: 1.75rem;
  color: #ffffff;
  width: 11.563rem;
  height: 5.125rem;
  text-align: left;
  font-size: 1.5rem;
  margin-top: 0.813rem;
  margin-left: 1rem;
}
.resourcestextboxcardactions {
  float: right;
  margin-top: 6rem;
}
.resourcestextboxcardbutton {
  font-size: 1.125rem;
  color: #ffffff;
  text-transform: capitalize;
}
.resourcessubscribebox {
  max-width: 27.5rem;
  height: 8.313rem;
}
.resourcessubscribetitle1 {
  color: #333435;
  vertical-align: top;
  font-weight: bold;
  font-size: 1.5rem;
  letter-spacing: 0.019rem;
  line-height: 1.75rem;
}

.resourcessubscribetitle2 {
  margin-top: 0.3rem;
  text-align: left;
  font-size: 1rem;
  line-height: 1.125rem;
  color: #333435;
}

.resourcessubscribebutton {
  height: 48px;
  border-radius: 0;
  color: #ffffff;
  background-color: #23aacd;
  width: 64px;
  float: right;
}

.resourcessubscribeinput {
  max-width: 26.25rem;
  margin-top: 15px;
  height: 49px;
  border: 1px solid #979797;
}

.resourcessectioncard {
  margin-top: 1.313rem;
  width: 90%;
}

.resourcessectiontitle {
  text-align: center;
  font-size: 1.875rem;
  font-weight: lighter;
  margin-top: 4.25rem;
  line-height: 2.375rem;
}

.resourcessectionsubscribe {
  margin-top: 4.438rem;
}

.resourcessectiontextboxcard {
  margin-top: 4.188rem;
}
.videoCard {
  max-width: 20.813rem;
  height: 18.25rem;
  margin: 1rem;
}
.videoCardMedia {
  height: 11rem;
}
.videoCardHeader {
  max-width: 19.25rem;
  height: 2.5rem;
  font-family: Arial;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.7px;
  text-align: left;
  color: #404347;
}
.videoCardAvatar {
  float: left;
  height: 2.125rem;
  width: 2.125rem;
  margin-top: 1.188rem;
}
.videoCardName {
  max-height: 1.25rem;
  margin-top: 1.75rem;
  width: 7.625rem;

  font-weight: 600px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.7px;
  color: #929090;
}
.videoCardLink {
  text-decoration: none;
  color: #929090;
}
.videoCardFee {
  max-height: 1.313rem;
  color: #28b700;
  margin-top: 1.7rem;
}
.videoCardFeeText {
  font-weight: 800;
}
.paginationArrow {
  width: 1%;
  height: 1%;
  border-radius: 100px;
  background-color: red;
}
.videoTitle {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 23, 44px;
  margin-bottom: 1rem;
  margin-left: 1.7rem;
  color: #545353;
}
.videoPagePopular {
  padding-top: 1.25rem;
  padding-bottom: 2.813rem;
  background-color: #f3f3f3;
}
.videoPageTrending {
  padding-top: 1.25rem;
  padding-bottom: 2.813rem;
  background-color: #ececec;
}
.videoPageNewest {
  padding-top: 1.25rem;
  background-color: #f7f7f7;
  padding-bottom: 2.813rem;
}
.eventPageHead {
  font-weight: 700;
  font-size: 20px;
  line-height: 23.44px;
  color: #545353;
  padding-bottom: 50px;
  display: grid;
  place-items: left;
  padding-left: 15px;
  margin-left: 25px;
}
.eventPageGridContainer1 {
  padding-bottom: 30px;
  padding-left: 43px;
  padding-top: 30px;
  filter: drop-shadow(0px 10px rgba(0, 0, 0, 0.1));
}
.eventPageContainer1 {
  margin-left: 0px;
}
.eventPageBox {
  padding-top: 29px;
  background-color: #f7f7f7;
  padding-bottom: 45px;
  width: 100%;
}
.eventPageBox2 {
  padding-top: 29px;
  background-color: #ececec;
  padding-bottom: 45px;
}
.signupModalHeader {
  font-weight: bold;
  font-size: 23px;
  line-height: 27px;
  color: #000000;
  min-width: 240px;
}
.signupModalDesc {
  margin-top: 15px;
  font-size: 16px;
  line-height: 18px;
  color: #202020;
  min-width: 240px;
}
.signupModalInputBase {
  height: 48px;
  padding: 5%;
  border-radius: 6px;
  border: 1px solid #8692a6;
  margin-top: 8px;
  font-size: 14px;
  line-height: 17px;
  color: #8692a6;
}
.signupModalInputHead {
  font-size: 14px;
  line-height: 19px;
  color: #696f79;
  text-align: left;
}
.signupModalInput {
  height: 94px;
  width: 100%;
  justify-content: center;
}
.signupModalRadio {
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  color: #696f79;
}
.signupModalRegisterForm {
  margin-top: 12px;
  width: 100%;
  height: 52px;
}
.registerAccount {
  height: 52px;
  background: linear-gradient(90deg, #f7b407 3.64%, #fd954e 100%);
  border-radius: 6px;
  text-align: center;

  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.registerAccount.Mui-disabled {
  background: linear-gradient(90deg, #f7b30760 3.64%, #fd944e60 100%);
}
.signupModalFormMiddleText {
  margin-top: 15px;
  width: 100%;
  padding-left: 49%;
  padding-right: 50%;
  font-size: 12px;
  line-height: 15px;
  color: #bababa;
}
.signupModalLinkedinForm {
  margin-top: 15px;
  width: 100%;
  height: 52px;
}
.registerLinkedin {
  max-width: 429px;
  height: 52px;
  border-radius: 6px;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  border: 1px solid #a9a9a9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  justify-content: left;
  padding-left: 12%;
}
.heroSliderFormInputBase {
  height: 52px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
}
.heroSliderFormButton {
  height: 52px;
  border-radius: 5px;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  position: absolute;
}
.heroSliderFormSearchButton {
  color: white;
  margin-right: 20px;
}
.heroSliderSearchBox {
  max-width: 583px;
  max-height: 52px;
  background-color: #ffffff;
}
.circleButtonLeft {
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  margin-top: 70%;
  display: grid;
}
.circleButtonRight {
  background: white;
  margin-top: 70%;
}
.aboutHeaderImage {
  background-image: url(../images/about.png);
  max-width: 1920px;
  width: auto;
}
.aboutHeader {
  color: #202020;
  font-size: 30px;
  font-weight: bold;
  line-height: 130%;
  margin-top: 47px;
  margin-bottom: 17px;
}
.aboutHeaderText {
  color: #202020;

  font-size: 20px;
  line-height: 30px;
  margin-top: 17px;
  margin-bottom: 48px;
}
.aboutSUS {
  color: #151f2f;
  font-size: 30px;
  font-style: light;
  line-height: 100%;
  vertical-align: top;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 20px;
}
.aboutCardMedia {
  height: 410px;
  max-width: 615px;
  width: 100%;
  margin-top: 48px;
  margin-bottom: 48px;
  border-radius: 30px;
}
.resourcescarddescription {
  color: #202020;

  font-style: regular;
  font-size: 18px;
  line-height: 124%;
  margin-top: 22px;
  margin-left: 35px;
  margin-right: 37px;
  margin-bottom: 37px;
  text-align: justify;
  vertical-align: top;
}

.investorsCard {
  max-width: 21.875rem;
  border-radius: 5px;
  margin-bottom: 2rem;
  background: #ffffff;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
}
.investorsCardHeader {
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: #5c5f64;
}
.investorsCardText {
  margin-top: 5%;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #5c5f64;
  min-height: 100px;
}
.investorsCardFooter {
  margin-top: 5%;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.894444px;
  color: #979797;
  text-align: left;
}
.investorsCard2Text {
  margin-left: 3%;
}
.investorsPage {
  margin-top: 2%;
  max-height: 873px;
  background-color: #e5e5e5;
}
.investorsPage2 {
  padding: 1.5%;
  padding-left: 8%;
}
.investorsPagePopularText {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #545353;
}
.Popular {
  margin-left: 9%;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #545353;
}
.investorsCardMedia {
  height: 160px;
  border-radius: 3px;
}
.publicExploreText {
  font-weight: bold;
  font-size: 29px;
  line-height: 35px;
  color: #151f2f;
  margin-top: 27px;
  padding-left: 10px;
}

.publicExploreInputBase {
  width: 100%;
  padding-left: 2%;
  height: 52px;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.eventPitchInputBase2 {
  width: 100%;
  padding-left: 2%;
  font-size: 11px;
  height: 44px;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #dfe0eb;
}
.investorsPagePopular {
  padding-bottom: 2rem;
  background-color: #f3f3f3;
}
.investorsPageTrending {
  padding-bottom: 2rem;
  background-color: #ececec;
}

.investorsTitle {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #545353;
  padding: 1rem;
}
.startupdashcardbox3 {
  max-width: 350px;
  min-height: 160px;
}
.startupPageHeader {
  font-weight: bold;
  margin-top: 1.25rem;
  line-height: 23, 44px;
  color: #545353;
}
.startupPagePopular {
  padding-bottom: 2rem;
  background-color: #f3f3f3;
}
.startupPageTrending {
  padding-bottom: 2rem;
  background-color: #ececec;
}
.startupPageNewest {
  background-color: #f7f7f7;
  padding-bottom: 2rem;
}
.startupTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #545353;
  padding-top: 1.5rem;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.MyVideosButton {
  position: absolute;
  height: 52px;
  border-radius: 5px;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  text-transform: capitalize;
}
.eventPageButton {
  position: absolute;
  width: 114px;
  height: 52px;
  border-radius: 5px;
}
.MyVideosButton2 {
  position: absolute;
  height: 52px;
  border-radius: 0px 5px 5px 0px;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  text-transform: capitalize;
}
.eventPitchSearchButton {
  position: absolute;
  min-width: 45px;
  height: 44px;
  border-radius: 0px 5px 5px 0px;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  text-transform: capitalize;
}
.MyEventButton {
  width: 114px;
  height: 42px;
  padding-left: 30px;
  border-radius: 5px;
  background: linear-gradient(90deg, #f7b407 100%, #fd954e 100%);
}
.MyVideosButtonText {
  color: white;
}
.MyEventButtonText {
  color: white;
  padding-right: 15px;
}
.MyVideosButtonGrid {
  margin-top: 1.2rem;
}
.MyEventsButton {
  position: absolute;
  height: 52px;
  margin-top: 1rem;
  border-radius: 5px;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
}
.MyEventsButtonText {
  color: white;
}
.MyEventsButtonGrid {
  margin-top: 1.2rem;
}
.MuiFormControlLabel-label {
  padding-left: 3px;
  margin-right: 5px;
}

.FullPageVideoTitle {
  font-size: 25px;
  font-weight: 700;
  line-height: 29px;
  text-align: left;
  margin-left: 1.313rem;
  margin-bottom: 8px;
  margin-top: 1rem;
}
.FullPageVideoContent {
  font-family: ArialMT;
  font-size: 21px;
  line-height: 25px;
  letter-spacing: 0.699999988079071px;
  text-align: left;
  margin-left: 1.313rem;
  margin-bottom: 2rem;
}

.playerWrapper {
  height: 100%;
}
.totalComment {
  font-size: 20px;
  font-style: normal;
  line-height: 25px;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.125rem;
  padding-bottom: 1rem;
  color: #151f2f;
}
.commentAvatarGroup {
  padding-left: 2rem;
  padding-top: 1.5rem;
  padding-right: 35px;
}
.likeContent {
  margin-top: 1rem;

  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  color: #5c5f64;
  padding-left: 0.625rem;
  padding-right: 1rem;
  text-transform: capitalize;
}
.likeButton {
  margin-top: 1.25rem;
}
.shareContent {
  margin-top: 1rem;

  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  color: #5c5f64;
  margin-left: 0.625rem;
  text-transform: capitalize;
}
.shareButton {
  margin-top: 1.25rem;
  margin-left: 1rem;
}
.commentAvatar {
  margin-top: 1.3rem;
  margin-left: 1.313rem;
  margin-bottom: 2.063rem;
  width: 52px;
  height: 52px;
}
.addCommentAvatar {
  margin-top: 1.3rem;
  margin-left: 1.313rem;
  margin-bottom: 2.063rem;
  width: 52px;
  height: 52px;
}
.commentButton {
  position: absolute;
  height: 45px;
  border-radius: 5px;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
}
.commentButtonText {
  color: white;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
}
.commentName {
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
  color: #52a7c9;
  margin-top: 1rem;
}
.commentText {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #151f2f;
  padding-top: 7px;
  padding-bottom: 7px;
}
.showMoreText {
  font-size: 16px;
  font-style: normal;
  font-weight: 700px;
  line-height: 19px;
  color: #979797;
  width: 200px;
  text-transform: capitalize;
}
.showMoreButton {
  width: 100%;
  &:hover {
    background-color: white;
  }
}
.carouselButton {
  width: 41px;
  height: 41px;
  border-radius: 20.5px;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  color: #000000;
}
.pitchEventCardButton {
  text-transform: capitalize;
  height: 36px;
  width: 126px;
  border: 1px solid #545353;
  border-radius: 6px;
  color: #000000;
  margin-top: 1rem;
}
.pitchEventPageCardButton {
  text-transform: capitalize;
  height: 26px;
  width: 125px;
  border-radius: 4px;
  background: linear-gradient(90deg, #f7b407 5%, #fd954e 100%);
}
.pitchEventPageCardButtonCancel {
  text-transform: capitalize;
  border: 0.3px solid #5c5f64;
  border-radius: 4px;
  height: 26px;
  width: 125px;
  border-radius: 4px;
}
.carouselButtonNewsFeed {
  min-width: 31px;
  height: 31px;
  border-radius: 20.5px;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  color: #000000;
}
.carouselButtonNewsFeed:hover {
  color: white;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
}
.carouselButton:hover {
  color: white;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
}
.pitchEventCardButton:hover {
  color: white;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: orange;
}
.eventPost {
  min-width: 994px;
  background: #ffffff;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.eventPostHeader {
  font-size: 20px;
}

.cardTitle {
  color: #ee2690;

  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.7px;
}
.eventPostTitle1 {
  font-weight: bold;
  font-size: 19px;
  line-height: 18px;
}
.eventPostAbout {
  font-size: 14px;
  line-height: 26px;
  min-height: auto;
}
.eventDate {
  font-size: 12px;
  line-height: 15px;
  padding-left: 19px;
  padding-top: 20px;
}
.eventLocation {
  width: 35px;
  height: 32px;
  color: #5c5f64;
  float: left;
}

.eventLocationIcon {
  font-size: 12px;
  line-height: 15px;
  padding-left: 17px;
  padding-top: 20px;
}
.userPostDivider {
  padding-top: 16.5px;
}
.eventPostButton {
  padding-top: 30px;
}
.eventPostButtonTitle {
  text-transform: capitalize;
  font-size: 18px;
  color: #5c5f64;
  margin-left: 5px;
}
.sharecommenticon {
  color: #5c5f64;
}
.eventBigCardIcon {
  width: 35px;
  height: 32px;
  color: #5c5f64;
  float: left;
}
.eventPageMeeting {
  z-index: 1;
  position: absolute;
  width: 8rem;
  height: 2.08rem;
  background-color: #23aacd;
  color: #ffffff;
  border-radius: 5px 0px 5px 0px;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}
.eventPageWebinar {
  z-index: 1;
  position: absolute;
  width: 8rem;
  height: 2.08rem;
  background-color: #23aacd;
  color: #ffffff;
  border-radius: 5px 0px 5px 0px;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}
.startupdashboardtitle {
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  padding-top: 55px;
  color: #151f2f;
}
.startupdashbordimage {
  height: 470px;
  border-radius: 20px;
  max-width: 100%;
}
.startupdashboardtitle2 {
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  padding-top: 23px;
}
.startupdashboardtitle3 {
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
}
.startupdashboardcontent {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #5c5f64;
  padding-top: 70px;
}
.startupdashboardıconbox {
  text-align: center;
  margin-top: 50px;
  font-size: 50px;
  padding-bottom: 50px;
}
.startupdashboardtitle4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  margin-top: 38px;
}
.startuplistbox {
  color: #999999;
  display: inline-block;
  text-align: left;
}
.startupdashboardtitle5 {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #000000;
  text-align: center;
  padding-top: 46px;
  padding-bottom: 70px;
}
.startupdashbordcardimage {
  height: 356px;
  width: 100%;
}
.startupdashbordcardimage2 {
  height: 356px;
  max-width: 100%;
  min-height: 100%;
}
.startupdashboardcard {
  width: 100%;
  height: 356px;
}
.startupdashbordcardtitle {
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: #3c3a3a;
  margin-top: 20px;
  padding-bottom: 32px;
}
.startupdashbordcard2 {
  margin-top: 69px;
  margin-bottom: 98px;
}
.startupdashboardcardcontentbasic {
  font-size: 16px;
  line-height: 26px;
  color: #5c5f64;
}
.startupdashboardcardcontent {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.startupdashboardtitle6 {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  padding-top: 46px;
}
.startupdashboardblogtitle {
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  color: #5c5f64;
  letter-spacing: 0.5px;
  text-align: center;
  padding-top: 46px;
  margin-bottom: 29px;
}
.startupdashbordcardimage4 {
  height: 480px;
  width: 100%;
}
.startupdashboardbutton {
  width: 105px;
  background-color: #dfe0eb;
  height: 29px;
  border-radius: 2px;
  margin-top: 27px;
  margin-left: 24px;
  margin-bottom: 13px;
}
.startupdashboardbuttontext {
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.7px;
  color: #404347;
}
.startupdashboardcartitle2 {
  margin-left: 24px;

  font-size: 21px;
  line-height: 26px;
  letter-spacing: 0.7px;
  color: #404347;
  margin-bottom: 5px;
}
.startupdashboardcardcontent2 {
  font-size: 14px;
  line-height: 24px;
  margin-left: 24px;
  margin-bottom: 16px;
}
.startupdashboardcardfee {
  font-size: 12px;
  line-height: 15px;
  color: #5c5f64;
  margin-left: 24px;
  margin-bottom: 22px;
}
.startupdashcard {
  max-width: 329px;
  min-height: 267px;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.startupdashcardMedia {
  max-width: 282px;
  height: 150px;
  border-radius: 5px;
  filter: drop-shadow(0px 10px 24px rgba(0, 0, 0, 0.1));
}
.startupdashcardbutton {
  margin: left 24px;
  background: #dfe0eb;
  border-radius: 2px;
  color: #404347;
  margin-bottom: 18px;
  height: 29px;

  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.7px;
  margin-top: 78px;
}
.startupdashcardcontent {
  font-size: 14px;
  line-height: 24px;
  color: #404347;
  padding-top: 2px;
}
.startupdashcardbox2 {
  min-height: 80px;
}
.startupdashcardfee {
  font-size: 12px;
  font-size: 12px;
  line-height: 15px;
  color: #5c5f64;
  margin-left: 17px;
}
.startupdashcardbox {
  max-width: 282px;
  height: 4.688rem;
}
.startupdashcardbox2 {
  max-width: 329px;
}
.timeline {
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 165px;
  border: 1px solid #dfe0eb;
}
.ourtimeline {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  margin-top: 38px;
  margin-bottom: 52px;
}
.locationIcon {
  width: 15px;
  height: 13px;
}
.marketPlaceTitle {
  text-align: center;

  font-size: 20px;
  line-height: 25px;
  color: #151f2f;
  margin-bottom: 21px;
}
// .MuiButtonBase-root {
//   color: silver ;
// }
.Mui-checked {
  color: orange;
}
.videoEnteredPage {
  padding-top: 1.25rem;
  padding-bottom: 2.813rem;
  background-color: #f3f3f3;
}
.videosPageEnteredSearchBox {
  border: 1px solid #e6e6e6;
  padding-bottom: 19px;
  background-color: #ffffff;
}
.CreateProfileModalMediaBox {
  border-radius: 50%;
  max-width: 160px;
  max-height: 160px;
  width: 160px;
  height: 160px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-image: linear-gradient(90deg, rgb(247, 180, 7) 0%, rgb(253, 149, 78) 100%);
}
.CreateProfileUseCamera {
  height: 44px;
  background: #ffffff;
  border: 1px solid #f7b407;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  color: white;
}
.CreateProfileUseCameraButton {
  height: 44px;
  border-radius: 5px;
}
.CreateProfileUseCameraButtonText {
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  color: #f7b407;
  text-transform: capitalize;
  min-width: 110px;
}
.CreateProfileUploadButtonText {
  font-weight: bold;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  text-transform: capitalize;
  min-width: 130px;
}
.CreateProfileUpluadPhoto {
  height: 44px;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.CreateProfileUpluadPhotoButton {
  height: 44px;
  border-radius: 5px;
}

.CreateProfileModalHeader {
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  padding-left: 2%;
}
.CreateProfileModalHeaderClose {
  float: right;
}
.CreateProfileAddIndustry {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #52a7c9;

  text-transform: capitalize;
}
.CreateProfileContactText {
  font-size: 16px;
  line-height: 19px;
  color: #696f79;
}

.ProfileModalRadio {
  margin-top: 24px;
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  color: #696f79;
  margin-left: 9px;
}
.carouselButton {
  min-width: 41px;
  height: 41px;
  border-radius: 20.5px;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  color: #000000;
}
.carouselButton:hover {
  color: white;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
}
.filterListText {
  font-weight: 400;
  font-size: 20px;
  line-height: 23.44px;
  color: #aaaaaa;
  margin-top: 10px;
}

.videoEnteredPage {
  padding-top: 1.25rem;
  padding-bottom: 2.813rem;
}
.FilterButton {
  max-width: 230px;
}
.eventCalendarHeaderText {
  font-size: 16px;
  line-height: 20px;
  color: #2390cd;
}
.eventCalendarFooterText {
  font-size: 10px;
  line-height: 13px;
  color: #5c5f64;
}
.postInput {
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 12px;
  line-height: 14px;
  color: #cacaca;
  padding-left: 30px;
}
.newsFeedContacts {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #151f2f;
}
.contactsProfileName {
  font-size: 16px;
  line-height: 20px;
  color: #151f2f;
}
.contactsAllContactsForm {
  width: 146px;
  height: 36px;
  border-radius: 5px;
}
.contactsAllContactsButton {
  height: 36px;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
}
.tutorialsCard {
  max-width: 230px;
  background: #ffffff;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: left;
  padding-left: 5%;
}
.tutorialCardBox {
  background: #ffffff;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.tutorialCardButton {
  height: 36px;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.newsfeedPhoto {
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.marketPlaceCard {
  max-width: 270px;
}
.marketPlaceCardImg {
  width: 106px;
  height: 106px;
  border-radius: 53px;
  margin: auto;
  border: 4px solid #fff;
  z-index: 999;
}
.marketPlaceBioName {
  font-size: 25px;
  line-height: 31px;
  color: #52a7c9;
}
.marketPlaceBioProfession {
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
}
.locationIcon {
  width: 15px;
  height: 13px;
}
.marketPlaceBioLocation {
  font-size: 14px;
  line-height: 15px;
  color: #5c5f64;
}
.marketPlaceMedia {
  width: 106px;
  height: 3.313rem;
  margin: auto;
}
.marketPlaceBioExperience {
  height: 23px;
  font-size: 10px;
  margin: auto;
  color: #fff;
  background: #23aacd;
  border-radius: 2px;
}
.marketPlaceExperienceTitle {
  font-size: 10px;
}
.startupcarouselButton {
  min-width: 41px;
  height: 41px;
  border-radius: 20.5px;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  color: #000000;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.startupcarouselButton:hover {
  color: white;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
}
.experience {
  width: 205px;
  height: 50px;
}
.marketPlacePageTitle {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  padding-left: 30px;
  padding-top: 30px;
  color: #545353;
}
.marketplacePagePopular {
  padding-bottom: 2rem;
  background-color: #f3f3f3;
}
.marketplacePageTrending {
  padding-bottom: 2rem;
  background-color: #ececec;
}
.marketplacePageNewest {
  background-color: #f7f7f7;
  padding-bottom: 2rem;
}
.marketplaceFrames {
  max-width: 1105px;
  width: auto;
  min-height: 288px;
  margin: 10px;
  border-radius: 5px;
}
.marketplaceFramesMedia {
  filter: drop-shadow(0 10px 24px rgba(0, 0, 0, 0.1));
  margin-top: 32px;
  margin-left: 38px;
}
.marketplaceFramesBioDescBox {
  min-height: 52px;
  margin-left: 38px;
  margin-top: 5px;
}
.marketplaceFramesBioDesc {
  font-size: 14px;
  line-height: 26px;
  color: #151f2f;
}

.marketPlaceExperienceTitle {
  font-size: 10px;
}
.FilterButton {
  float: left;
}

.FilterCategoryButton {
  margin-bottom: -1rem;
}

.eventFullPageFooterHeader {
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  color: #545353;
  padding-bottom: 1.68rem;
  padding-top: 2.063rem;
  padding-left: 20px;
}
.carouselButton {
  min-width: 41px;
  height: 41px;
  border-radius: 20.5px;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  color: #000000;
}
.carouselButton:hover {
  color: white;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
}
.marketPlaceBioButtonBox {
  width: 100%;
  height: 36px;
  margin-top: 28px;
  text-align: center;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  color: #ffffff;
}
.marketPlaceBioButtonTitle {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  text-transform: capitalize;
}

.followerBar {
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
}
.followerBarNumber {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
}
.followerBarTitle {
  font-size: 14px;
  line-height: 18px;
  padding-top: 4px;
}
.followerBarButton {
  width: 245px;
  height: 36px;
  margin-top: 17px;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  color: #ffffff;
}
.followerBarButtonTitle {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  text-transform: capitalize;
}
.userProfileAbout {
  background: #ffffff;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.userProfileAboutTitle {
  padding-top: 25px;

  font-size: 16px;
  line-height: 20px;
  color: #151f2f;
}
.userProfileAboutTitle2 {
  font-size: 16px;
  line-height: 20px;
  color: #151f2f;
  font-weight: 600;
}
.userPost {
  background: #ffffff;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.userPostHeader {
  font-size: 16px;
}
.userPostImg {
  height: 323px;
}
.userPostTitle1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  padding-top: 15px;
  padding-left: 19px;
}
.userPostAbout {
  font-size: 14px;
  line-height: 26px;
}
.userPostAboutBox {
  padding-left: 19px;
  padding-top: 8px;
}
.userPostDate {
  font-size: 12px;
  line-height: 15px;
  padding-left: 19px;
  padding-top: 20px;
}
.daterangeicon {
  width: 15px;
  height: 12px;
  color: #5c5f64;
}
.eventDivider {
  padding-top: 16.5px;
}
.userPostAvatarGroup {
  padding-top: 31.5px;
}
.eventAvatarGroup {
  padding-top: 31.5px;
}
.userPostButton {
  padding-top: 37.5px;
}
.userPostButtonTitle {
  text-transform: capitalize;
  font-size: 13px;
  color: #5c5f64;
  margin-left: 5px;
}
.sortText {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
  color: #aaaaaa;
}
.trendingCardArea {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #151f2f;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 15px;
}
.carouselButtonmin {
  min-width: 30px;
  height: 30px;
  border-radius: 15px;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  color: #000000;
}
.carouselButtonmin:hover {
  color: white;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
}
.viewsAllButton {
  width: 86px;
  height: 36px;
  margin-top: 15px;
  text-align: center;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  color: #ffffff;
}
.viewsAllButtonTitle {
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
}

.startupdashcardbox5 {
  max-width: 350px;
}
.startupdashcardMedia2 {
  max-width: 350px;
  min-height: 150px;
  height: 150px;
  border-radius: 3px;
}
.startupdashcard8 {
  max-width: 350px;
  min-height: 140px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  // width: 350px ;
}

.startupdashcardcontent4 {
  padding-top: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
}
.startupdashcardfee4 {
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  letter-spacing: 0.89px;
  padding-top: 0.8rem;
  color: #23aacd;
  height: 40px;
}
.startupCardHeader4 {
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  text-align: left;
  padding-top: 7px;
  color: #5c5f64;
}
.startupCardAvatar4 {
  float: left;
}
.contentbox {
  min-height: 100px;
}
.videoCardDuration {
  position: absolute;
  height: 7px;
  right: 2.2%;
  top: calc(53% - 7px / 2 - 2.5px);
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 9px;
  text-align: center;
  letter-spacing: 0.7px;
  color: #ffffff;
  padding: 3px;
  background: #000000;
  width: 30px;
  height: 14px;
}
.UserProfileFollowImg {
  width: 35px;
  height: 35px;
  margin-left: 20px;
  border-radius: 17.5px;
  filter: drop-shadow(0px 10px 24px rgba(0, 0, 0, 0.1));
}
.UserProfileFollowName {
  font-size: 16px;
  line-height: 20px;
  color: #151f2f;
  margin-left: 9px;
}
.UserProfileFollowFollowers {
  font-size: 10px;
  line-height: 13px;
  margin-left: 9px;
  color: #5c5f64;
}
.UserProfileFollowButton {
  width: 55px;
  height: 19px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 2px;
  margin-left: 15px;
  margin-top: 5px;
}
.UserProfileFollowButtonTitle {
  text-transform: capitalize;
  font-size: 10px;
  line-height: 12px;
  color: #5c5f64;
}
.navbarButton {
  &:hover {
    background-color: white;
  }
}
.navbarButtonText {
  color: '#1C1D1F';
}
.filterAccordionMobile {
  display: none;
}
@media (max-width: 900px) {
  .filterWebMenu {
    display: none;
  }
  .filterAccordionMobile {
    display: flex;
  }
}
.filterMobileMenuMarketPlace {
  display: none;
}
@media (max-width: 900px) {
  .filterWebMenuMarketPlace {
    display: none;
  }
  .filterMobileMenuMarketPlace {
    display: flex;
  }
}
.filterlistform {
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  border: 0px;
  outline: 0px;
  border-radius: 5px;
}

.bottomnavigation {
  max-width: 46px;
}
.startupmarketplace {
  margin-bottom: 89px;
  margin-top: 40px;
}
.Listitemanswer {
  font-weight: bolder;
  color: #5c5f64;
  margin-top: -15px;
  font-size: 20px;
}
#demo-positioned-menu {
  z-index: 99999;
}

.MuiModal-root {
  z-index: 9999;
}

.MuiAutocomplete-popper {
  z-index: 99999;
}
.btn-turquois {
  background: #52a7c9;
}
.FullPageVideoCommentButtonMobile {
  color: #f7b407;
  position: absolute;
  display: none;
  margin-top: 1.5rem;
}
.FullPageVideoCommentButtonWeb {
  display: flex;
}
@media (max-width: 730px) {
  .FullPageVideoCommentButtonWeb {
    display: none;
  }
  .FullPageVideoCommentButtonMobile {
    display: flex;
    margin-left: 1rem;
  }
  .FullPageVideoAddComment {
    margin-left: 1.5rem;
  }
}
.FullPageVideoAddComment {
  margin-top: 1.5rem;
  border: 1px solid #d2d2d2;
  border-radius: 50px;
  padding: 10px;
}
@media (max-width: 420px) {
  .addCommentAvatar {
    display: none;
  }
  .FullPageVideoAddComment {
    margin-left: -1rem;
  }
  .FullPageVideoCommentButtonMobile {
    margin-left: -3rem;
  }
}
@media (max-width: 333px) {
  .commentAvatar {
    display: none;
  }
  .commentName {
    margin-left: -3rem;
  }
  .commentText {
    margin-left: -3rem;
  }
}
@media (max-width: 654px) {
  .commentAvatarGroup {
    margin-left: -1rem;
  }
  .totalComment {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .likeButton {
    margin-left: -1.5rem;
  }
  .likeContent {
    margin-right: -0.75rem;
  }
  .shareButton {
    margin-left: -0.25rem;
  }
  .shareContent {
    margin-right: -1rem;
  }
}
@media (max-width: 493px) {
  .likeContent {
    display: none;
  }
  .shareContent {
    display: none;
  }
}
@media (max-width: 447px) {
  .likeContent {
    display: flex;
  }
  .shareContent {
    display: flex;
  }
  .likeButton {
    margin-left: 0rem;
  }
}
@media (max-width: 800px) {
  .startupbutton {
    display: flex;
    margin-bottom: 1rem;
  }
}
@media (min-width: 800px) {
  .startupbutton {
    display: none;
  }
}
@media (max-width: 800px) {
  .startupmarketmobile {
    display: flex;
  }
}
@media (min-width: 800px) {
  .startupmarketmobile {
    display: none;
  }
}
.startupmarketplacediv {
  margin: 1rem;
}
.userGroupMembers {
  margin-top: 150px;
}
@media (max-width: 799px) {
  .startupmarketweb {
    display: none;
  }
}
.eventSearch {
  max-width: 1090px;
  min-width: 315px;
}
.moreVertSetings {
  float: right;
  z-index: 1;
}
.MoreVertIconText {
  color: #545353;
}
.eventPitchCard {
  max-width: 980px;
  min-height: 435px;
}
.eventPitchDivider {
  max-width: 680px;
}
.eventPitchDivider2 {
  max-width: 453px;
  padding-top: 1rem;
}
.eventPitchAccordion {
  margin-top: 1.25rem;
  margin-left: 32px;
  margin-right: 32px;
}
.eventPitchSmallCard {
  max-width: 480px;
  min-height: 320px;
}
.smallCardDivider {
  max-width: 348px;
}
.viewButtonText {
  font-size: 12px;
  color: #23aacd;
  text-decoration: underline;
  font-weight: bold;
  float: right;
}
.viewAllButton {
  min-width: 60px;
}
.viewAllButtonGrid {
  max-width: 100px;
}
.viewsubmissiontitle {
  float: left;
}
.viewsubmissonmodaltitle {
  float: left;
  font-size: 15px;
  line-height: 25px;

  margin-bottom: 18px;
}
.viewSubmissioncard {
  width: 100%;
  min-height: 172px;
  border-radius: 8px;
  border: 1px solid #dfe0eb;
}
.viewSubmissioncardimage {
  height: 100%;
  border-radius: 5px;
}
.viewSubmissioncardtitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #3c3a3a;
  text-align: left;
  padding-top: 18px;
  padding-bottom: 14px;
  color: #000000;
  line-height: 25px;
}

.viewSubmissioncardBox {
  height: 250px;
  min-width: 246px;
  min-height: 100%;
  padding-top: 1rem;
}
.viewSubmissionprofilebutton {
  width: 146px;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  color: white;
  text-transform: capitalize;
  float: left;
  margin-top: 10px;
}
.viewsubmissonmodaltitle2 {
  font-size: 15px;
  line-height: 25px;
  font-weight: bold;

  margin-bottom: 8px;
  margin-top: 7px;
  color: #252733;
  float: left;
  margin-left: 1rem;
}

.viewsubmissonmodalbutton {
  background-color: #ebedf0;
  border-radius: 8px;
  margin-left: 24px;
  margin-bottom: 13px;
  height: 40px;
  margin-top: -10px;
  width: 120px;
  float: left;
}
.viewsubmissonmodalbutton2 {
  background: #ffffff;
  border-radius: 8px;
  margin-right: 24px;
  margin-bottom: 13px;
  height: 40px;
  margin-top: -10px;
  width: 120px;
  border: 1px solid #dfe0eb;
  float: right;
}
@media (max-width: 599px) {
  .viewsubmissonmodalbutton2 {
    float: none;
    margin-left: 48px;
  }
  .viewsubmissonmodalbutton {
    float: none;
  }

  .childmodalopenbutton {
    float: none;
    margin-top: 0px;
    align-items: center;
    margin-right: 0px;
    margin-bottom: 10px;
  }
}
.viewSubmissionmodalcard2 {
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  margin-bottom: 20px;
}
.viewsubmissonmodalbutton2text {
  align-items: center;
  font-weight: bold;
  line-height: 20px;
  text-transform: capitalize;
}
.viewsubmissonmodalbutton3 {
  border-radius: 8px;
  height: 40px;
  width: 85px;
  margin-left: 18px;
  margin-top: 9px;
  margin-bottom: 10px;
}
.viewsubmissonmodalscorebox {
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  padding-top: 1rem;
  padding-left: 15px;
  padding-right: 33px;
  display: flex;
  justify-content: space-between;
  height: 90px;
}
.viewsubmissonmodalbutton3text {
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  padding-top: 6px;
}
.viewSubmissionchidmodaltitle {
  font-size: 18px;
  line-height: 28px;
  padding-top: 10px;
  text-align: left;
  padding-left: 20px;
  padding-bottom: 12px;
}
.viewSubmissionchidmodaltitle2 {
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 30px;
}
.viewSubmissionchidmodalradio {
  padding-top: 9px;
  color: #696f79;
  font-size: 16px;
  line-height: 19px;
}
.viewSubmissionchildmodalbutton {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  width: 150px;
  height: 44px;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
}
.marketPlaceFramesBorderTitle {
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 1px;
  color: #fff;
  margin-top: 7px;
  margin-left: 9px;
}
.marketPlaceFramesBorderBox {
  position: absolute;
  width: 101px;
  height: 35px;
  background-color: #52a7c9;
  border-radius: 5px 0px 8px;
}
.investorDashboardTitle {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #151f2f;
  margin-top: 58px;
}
.investorDashboardUserName {
  font-size: 25px;
  line-height: 31px;
  color: #52a7c9;
  margin-top: 17px;
  margin-left: 10px;
}
.investorDashboardSearchBox {
  max-width: 334px;
  width: 100%;
  height: 36px;
  border: 1px solid #dfe0eb;
  padding: 0.8%;
  border-radius: 8px;
}
.investorDashboardSearchInput {
  max-width: 300px;
  width: 92%;
}
.investorDashboardButton {
  max-width: 146px;
  width: 140%;
  height: 36px;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  margin-left: 15px;
}
.investorDashboardButtonTitle {
  font-size: 14px;
  text-transform: capitalize;
  text-align: center;
  color: #fff;
}
.investorDashboardCardArea {
  max-width: 100%;
  // width: 100%;
  margin-top: 20px;
  margin-bottom: 26px;
}
.investorDashboardCardAreaHeader {
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-bottom: 1.6rem;
}
.investorDashboardCardAreaSortBy {
  font-size: 14px;
  line-height: 16px;
  color: #5c5f64;
  text-align: center;
  margin-right: 10px;
}
.investorDashboardAutoComplete {
  width: 168px;
}
.investorDashboardEventCard {
  max-width: 100%;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  margin-bottom: 36px;
}
.investorDashboardEventCardMedia {
  height: 180px;
}
.investorDashboardEventCardHeader {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #23aacd;
}
.investorDashboardEventCardTitle1 {
  font-size: 12px;
  line-height: 15px;
  color: #5c5f64;
}
.investorDashboardEventCardTitle2 {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.7px;
  color: #ee2690;
}
.investorDashboardEventCardTitle3 {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.7px;
  color: #404347;
}
.investorDashboardEventCardAvatar {
  width: 23px;
  height: 23px;
  margin-top: 20px;
}
.investorDashboardEventCardOrganized {
  font-size: 12px;
  line-height: 15px;
  color: #5c5f64;
  margin-top: 23px;
  padding-left: 5px;
}
.investorDashboardEventCardButton {
  height: 40px;
  width: 60px;
  float: right;
}
.investorDashboardEventCardGoing {
  color: #fd954e;
}
.investorDashboardEventCardGoingText {
  text-transform: capitalize;

  font-size: 12px;
  line-height: 15px;
  color: #5c5f64;
  padding-left: 7px;
}
.investorDashboardEventCardShare {
  color: #5c5f64;
  font-size: medium;
}
.investorDashboardEventCardShareText {
  text-transform: capitalize;

  font-size: 12px;
  line-height: 15px;
  color: #5c5f64;
  padding-left: 7px;
}
.investorDashboardEventCalendarTitle2 {
  font-size: 16px;
  line-height: 20px;
  color: #666666;
  padding-top: 8px;
}
.investorDashboardEventCalendarHeader {
  margin-top: 32px;
  margin-left: 32px;

  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  color: #252733;
}
.calendar .MuiButtonBase-root {
  color: #5c5f64;
  font-size: 18px;
  word-spacing: 20px;
}
.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #fd954e;
  color: #fff;
}
.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
  background-color: #fd954e;
  color: #fff;
}
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #fd954e;
  color: #fff;
}
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
  background-color: #fd954e;
  color: #fff;
}
.investorDashboardCreateEventHeader {
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #252733;
}

.investorpagebuilderimage1 {
  height: 312px;
  border-radius: 20px;
  width: 100%;
}
.investorbuildercontent {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #999;
  padding-top: 30px;
  text-align: center;
}
.investorbuilderAnalyticsHeader {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #3c3a3a;
}
.investorbuilderbuttons {
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  height: 44px;
  width: 184px;
}
.investorbuilderbuttonstext {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #ffffff;
  text-align: center;
}
.questionsubmitmodalradio {
  background: #ffffff;
  border: 1px solid #8692a6;
  box-sizing: border-box;
  border-radius: 6px;
}
.QuestionModalInput {
  margin-top: 4px;
  height: 30px;
  width: 100%;
}
.QuestionModalInputBase {
  height: 30px;
  border-radius: 6px;
  border: 1px solid #8692a6;
  font-size: 14px;
  line-height: 17px;
  color: #8692a6;
  padding-left: 7px;
}
.CreateNewEventHeader {
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}
.CreateNewEventUploadArea {
  width: 100%;
  height: 312px;
  background: #fbfcff;
  border: 1px dashed #979797;
  box-sizing: border-box;
  border-radius: 25px;
}
.CreateNewEventUploadAreaText1 {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #3c3a3a;
}
.CreateNewEventUploadAreaText2 {
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #3c3a3a;
  max-width: 644px;
}
.CreateNewEventUploadAreaText3 {
  font-size: 14px;
  line-height: 18px;
  color: #3c3a3a;
}
.CreateNewEventComboBoxWebinarButton {
  height: 44px;
  border-radius: 5px;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  margin-top: 2px;
}
.CreateNewEventWhiteButton {
  background: #ffffff;
  border: 1px solid #dfe0eb;
  box-sizing: border-box;
  border-radius: 8px;
  text-transform: capitalize;
  height: 36px;
  padding-left: 15%;
  padding-right: 15%;
}
.CreateNewEventOrangeButton {
  height: 36px;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  text-transform: capitalize;
  padding-left: 25%;
  padding-right: 25%;
}
.CreateNewEventDate {
  padding-top: 48px;
}
.CreateNewEventWhiteButtonText {
  font-size: 14px;
  line-height: 16px;
  color: #5c5f64;
}
.CreateNewEventOrangeButtonText {
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.ForgotPasswordText {
  font-size: 16px;
  line-height: 19px;

  text-transform: capitalize;
  color: #696f79;
  font-weight: 500;
}
.viewsubmissonmodalbutton3 {
  border-radius: 8px;
  height: 40px;
  width: 85px;
  margin-left: 18px;
  margin-top: 9px;
  margin-bottom: 10px;
}
.viewsubmissonmodalscorebox {
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  padding-top: 1rem;
}
.viewsubmissonmodalbutton3text {
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  padding-top: 6px;
}
.viewSubmissionchidmodaltitle {
  font-size: 18px;
  line-height: 28px;
  padding-top: 10px;
  text-align: left;
  padding-left: 20px;
  padding-bottom: 12px;
}
.viewSubmissionchidmodaltitle2 {
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 30px;
}
.childmodalopenbutton {
  margin-top: -50px;
  float: right;
  margin-right: 10px;
  width: 170px;
  height: 40px;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
}
.childmodalopentext {
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;

  color: #000000;
}
.error-message {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #db3f62;
}
.investorDashboardEventAnalyticsTitle1 {
  font-size: 19px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #9fa2b4;
  padding-top: 13px;
  margin-left: 26px;
}
.investorDashboardEventAnalyticsNumber {
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 1px;
  color: #252733;
  padding-top: 11px;
  margin-left: 26px;
}
.investorDashboardEventAnalyticsGreenButton {
  min-width: 36px;
  height: 36px;
  border-radius: 20.5px;
  background-color: #19d800;
  color: #fff;
}
.investorDashboardEventAnalyticsCard {
  max-width: 580px;
  max-height: 761px;
  padding-top: 26px;
  padding-bottom: 30px;
}
.investorDashboardEventAnalyticsCardBox1 {
  max-width: 516px;
  height: 344px;
  margin-top: 20px;
}
.investorDashboardEventAnalyticsCardBox2 {
  max-width: 516px;
  height: 206px;
  margin-top: 27px;
}
.investorDashboardEventAnalyticsCardHeader {
  font-size: 19px;
  font-weight: bold;
  color: #252733;
  line-height: 24px;
  letter-spacing: 0.4px;
}
.investorDashboardEventAnalyticsCardHeader2 {
  font-size: 14px;
  line-height: 18px;
  color: #9b9b9b;
}
.investorDashboardEventAnalyticsTopEvents {
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #9fa2b4;
  padding-top: 13px;
  padding-left: 26px;
}
.investorDashboardEventCalendar {
  width: 580px;
  height: 597px;
}
.investorDashboardEventAnalyticsTopEventsTitleBox {
  width: 300px;
  margin-top: 27px;
}
.investorDashboardEventAnalyticsTopEventsTitle {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.7px;
  color: #404347;
}
.investorDashboardEventAnalyticsTopEventsNumber {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  margin-top: 23px;
}
.investorDashboardAnalyticsMedia {
  width: 62px;
  height: 33px;
  border-radius: 3px;
  border: 3px solid #19d800;
  filter: drop-shadow(0px 10px 24px rgba(0, 0, 0, 0.1));
  margin-top: 20px;
  margin-left: 22px;
}
.investorDashboardAnalyticsSeeMore {
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-decoration-line: underline;
  margin-top: 22px;
  margin-left: 32px;
  color: #23aacd;
  width: 100%;
}
.QuestionModalButton {
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  height: 44px;
  width: 101px;
}
.ReceivedModalButton {
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  height: 44px;
  width: 162px;
}
.EventFullPageButton {
  margin-top: 9rem;
  margin-left: 1rem;
}
.investorDashboardEventAnalyticsNumberTitle {
  font-size: 14px;
  line-height: 18px;
  color: #9fa2b4;
  padding-left: 26px;
}
.EventPitchSmallCardAvatarHeader {
  font-size: 13px;
  font-weight: bold;
}
.pitchEventSmallCardButtonText {
  font-size: 10.5px;
  font-weight: 400;
  color: #ffffff;
}
.pitchEventPageCardButtonText {
  font-size: 12px;
  color: #ffffff;
}
.pitchEventPageCardButtonTextCancel {
  font-size: 12px;
  color: #5c5f64;
}
.marketPlaceBioDescription {
  font-size: 14px;
  text-align: center;
}
.marketPlaceCardDescBox {
  max-width: 245px;
}
.grecaptcha-badge {
  display: none !important;
}
