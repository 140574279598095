.about-section {
  width: 100%;
  &__content {
    padding: 0 50px 50px 50px;
  }

  &__add-social-media-links {
    margin-top: 33px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: #696f79;
  }
}

.industry-interests__chip {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-right: 5px;
  color: #ffffff;
  background: #23aacd;
}
