.page-builder {
  font-style: normal;
  font-weight: bold;
  margin-bottom: 46px;
  text-align: center;

  &__btn-save {
    width: 86px;

    &:disabled {
      cursor: not-allowed;
      background: #b2b2b2;
    }
  }
}
