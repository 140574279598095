.modal-container {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 1rem;
  outline: none;
  &-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #c0c0c0;

    & h2 {
      flex-grow: 1;
    }
  }
}
