.ss-button {
  width: 125px;
  height: 36px;
  background: linear-gradient(90deg, #f7b407 0%, #fd954e 100%);
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.19);
  color: #ffffff;
}

.ss-button-text {
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  text-transform: capitalize;
}
