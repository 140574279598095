.file-upload-drag-drop {
  background: #fbfcff;
  border: 1px dashed #979797;
  box-sizing: border-box;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;

  & .icon-upload-icon {
    margin-top: 40px;
    margin-bottom: 5px;
    display: block;
    font-size: 42px;
    color: #52a7c9;
  }

  &__buttons2 {
    position: absolute;
    top: 77px;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &__settings {
    top: 140px;

    & i {
      font-size: 32px;
      color: #edb740;
      z-index: 99999;
      background-color: #fff;
      height: 58px;
      width: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-menu {
      position: absolute;
      z-index: 9999;
      top: 250px;
      right: 40px;
      text-align: left;
    }
  }
}
