.MuiModal-root {
  outline: none;
  overflow-x: hidden;
}

.add-team-member-modal {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  &__container {
    max-width: 450px;
    width: 100%;
    display: flex;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 30px;
    outline: none;
  }

  &__title {
    position: relative;
    width: 100%;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: -25px;
    right: -25px;
  }

  & .file-upload-drag-drop {
    margin-bottom: 46px;
  }
}
