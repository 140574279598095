@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?82284432');
  src: url('../font/fontello.eot?82284432#iefix') format('embedded-opentype'),
    url('../font/fontello.woff2?82284432') format('woff2'),
    url('../font/fontello.woff?82284432') format('woff'),
    url('../font/fontello.ttf?82284432') format('truetype'),
    url('../font/fontello.svg?82284432#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?82284432#fontello') format('svg');
  }
}
*/
[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-globe:before {
  content: '\e800';
} /* '' */
.icon-user:before {
  content: '\e801';
} /* '' */
.icon-left-small:before {
  content: '\e802';
} /* '' */
.icon-right-small:before {
  content: '\e803';
} /* '' */
.icon-user-1:before {
  content: '\e804';
} /* '' */
.icon-upload-icon:before {
  content: '\e805';
} /* '' */
.icon-chart-pie-outline:before {
  content: '\e806';
} /* '' */
.icon-lightbulb:before {
  content: '\e807';
} /* '' */
.icon-group:before {
  content: '\e808';
} /* '' */
.icon-plus:before {
  content: '\e809';
} /* '' */
.icon-youtube:before {
  content: '\e80a';
} /* '' */
.icon-twitter:before {
  content: '\e80b';
} /* '' */
.icon-world:before {
  content: '\e80c';
} /* '' */
.icon-linkedin:before {
  content: '\e80d';
} /* '' */
.icon-dashboard-events:before {
  content: '\e80e';
} /* '' */
.icon-dashboard-analytics:before {
  content: '\e80f';
} /* '' */
.icon-dashboard-messages:before {
  content: '\e810';
} /* '' */
.icon-dashboard-overview:before {
  content: '\e811';
} /* '' */
.icon-dashboard-blog:before {
  content: '\e812';
} /* '' */
.icon-dashboard-page-builder:before {
  content: '\e813';
} /* '' */
.icon-math-plus:before {
  content: '\e814';
} /* '' */
.icon-check:before {
  content: '\e815';
} /* '' */
.icon-instagram:before {
  content: '\e816';
} /* '' */
.icon-facebook:before {
  content: '\e817';
} /* '' */
.icon-dashboard-settings:before {
  content: '\e818';
} /* '' */
.icon-menu:before {
  content: '\f0c9';
} /* '' */
.icon-calendar-empty:before {
  content: '\f133';
} /* '' */
.icon-youtube-play:before {
  content: '\f16a';
} /* '' */
.icon-history:before {
  content: '\f1da';
} /* '' */
