.input-group {
  text-align: left;
  & i {
    font-size: 26px;
    color: #979797;
    margin-right: 11px;
  }

  &__input-label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    line-height: 20px;
    color: #696f79;
  }

  &__left-label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-right: 12px;
    color: #807e7e;
  }

  & .MuiInput-underline:before,
  & .MuiInput-underline:after,
  & .MuiInputBase-root-MuiInput-root:hover:before {
    border-bottom: none;
  }
}
