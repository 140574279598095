.investment-criteria {
  &__box {
    border: 1px solid #dfe0eb;
    padding: 18px;
    border-radius: 8px;
  }

  &__percental-value {
    width: 100px;
    margin: 16px 0 16px;
  }

  &__center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
