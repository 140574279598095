.pitch-deck {
  width: 100%;
  background: #ffffff;
  padding: 0 50px 34px 50px;

  &__description {
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #999999;
  }

  &__avatars {
    display: flex;
    justify-content: center;
    margin-top: 41px;
    margin-bottom: 41px;

    & .icon-plus {
      color: #1672ec;
      cursor: pointer;
    }

    & .MuiAvatarGroup-avatar {
      height: 58px;
      width: 58px;
    }

    & .MuiAvatar-root {
      border: none;
      box-shadow: 0px 2.35714px 4.71429px rgba(15, 86, 179, 0.18),
        0px 4.71429px 9.42857px rgba(15, 86, 179, 0.18);

      &:first-child {
        background: #ffffff;
        z-index: 1;
      }
    }
  }
}
