.create-start-up-modal {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: absolute;
  top: 50%;
  outline: none;
  // margin-top: 100px;
  // margin-bottom: 500px;

  &__br {
    border-bottom: 1px solid #c0c0c0;
    padding-top: 0;
  }

  &__container {
    max-width: 1500px;
    width: 100%;
    display: flex;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 30px;
    outline: none;
  }

  &__title {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #c0c0c0;

    & h2 {
      flex-grow: 1;
    }
  }
}

@media (max-width: 640px) {
  .create-start-up-modal {
    top: 95%;
  }
}
